// * EXTERNAL IMPORTS
import { useActionData, useNavigate, useOutletContext } from "@remix-run/react";
import { AlertProps, Box, Card, CardBody, CardFooter, CardHeader, Image, Collapse, Container, Heading } from "@chakra-ui/react";
import { withZod } from "@remix-validated-form/with-zod";
import { HiOutlineArrowRight, HiOutlineKey, HiOutlineRectangleGroup } from "react-icons/hi2";
import { ValidatedForm } from "remix-validated-form";
import { z } from "zod";
import { ActionArgs } from "@remix-run/node";
import { redirect, typedjson, TypedJsonResponse } from "remix-typedjson";

// * INTERNAL IMPORTS
import ValidatedInput from "components/ValidatedInput";
import { ValidatedButton } from "components/ValidatedSubmitButton";
import { TypedActionResponse } from "types/custom_types";
import Alert from "components/Alert";
import getSupabaseServerClient from "lib/supabase.server";
import IconLogo from "public/images/icon-logo.png";
import { ValidatedCheckbox } from "components/ValidatedCheckbox";
import { useEffect, useRef, useState } from "react";
import mixpanel from "mixpanel-browser";
import ValidatedPassword from "~/components/ValidatedPassword";
import { GoogleSigninButton } from "~/components/GoogleSigninButton";
import { SupabaseOutletContext } from "~/root";
import { AuthLayout } from "~/pages/AuthLayout";
import { AuthContinueDivider } from "../components/AuthContinueDivider";
import { AuthActionWrapper } from "../components/AuthActionWrapper";
import { FooterLink } from "../components/AuthFooterLink";
import { AuthFieldsWrapper } from "../components/AuthFieldsWrapper";

export const meta = () => {
  return [{ charset: "utf-8" }, { title: "Login | CopyFrame" }, { viewport: "width=device-width,initial-scale=1" }];
};

// * LOADER
export const loader = async ({ request }: ActionArgs) => {
  const response = new Response();
  const supabase = getSupabaseServerClient({ request, response });
  const { data: sessionData, error: errorData } = await supabase.auth.getSession();
  if (errorData) return typedjson({ type: "error", message: "You are not logged in" }, { status: 401 });

  if (sessionData.session?.user.id) return redirect("/drive", { headers: response.headers });

  return typedjson({ type: "success" });
};

type AlertResponse = {
  status: AlertProps["status"];
  description: string;
};

// * ROUTE COMPONENT
export default function LoginRoute() {
  // const actionData = useActionData<TypedActionResponse>();
  const { supabase } = useOutletContext<SupabaseOutletContext>();

  const [alert, setAlert] = useState<AlertResponse | null>(null);

  const [urlError, setUrlError] = useState<string | null>(null);

  useEffect(() => {
    mixpanel.track("Page Viewed", { Pathname: "/login", "Page Name": "Login" });

    // Check if there's an error in the URL
    const hash = window.location.hash.substring(1); // Remove leading '#'
    const params = new URLSearchParams(hash);

    if (!params.has("error_description")) return; // No error to show

    // URLSearchParams won't work directly on the hash, so we need to manually find our value
    for (const [key, value] of params.entries()) {
      if (key === "error_description") {
        // Decoding to convert things like '%20' back to ' '
        setUrlError(decodeURIComponent(value));
        break; // No need to check further
      }
    }
  }, []);

  const formId = "login-form";
  const navigate = useNavigate();

  const handleAuthSubmit = async (data: { email: string; password: string }) => {
    mixpanel.identify(data.email);
    const { data: signInData, error: errorData } = await supabase.auth.signInWithPassword({
      email: data.email,
      password: data.password,
    });
    if (errorData) {
      setAlert({ status: "error", description: errorData.message });
    } else if (signInData?.user) {
      mixpanel.track("Logged In", { Provider: "Password" });
      setAlert(null);
      navigate("/drive");
    }
  };

  return (
    <AuthLayout
      title="Welcome back to CopyFrame"
      subtitle="Choose an option to access your account"
      bodyContent={
        <>
          <GoogleSigninButton label="Sign in with Google" redirectUrl="/drive" supabase={supabase} />
          <AuthContinueDivider text="or continue with" />
          <ValidatedForm id={formId} method="post" validator={authValidator} onSubmit={(data: any) => handleAuthSubmit(data)}>
            <AuthFieldsWrapper>
              <ValidatedInput name="email" label="Email address" type="email" formId={formId} onChange={() => setUrlError(null)} />
              <ValidatedPassword name="password" />
              <AuthActionWrapper>
                <ValidatedButton formId={formId} label="Log in to CopyFrame" loadingText="Logging in..." width="full" />

                <Collapse in={!!alert}>
                  <Alert status={alert?.status} description={alert?.description ?? ""} />
                </Collapse>
              </AuthActionWrapper>
            </AuthFieldsWrapper>
          </ValidatedForm>
        </>
      }
      footerContent={
        <>
          <FooterLink text="Prefer no password?" linkText="Log in with magic link" href="/magic-link" />
          <FooterLink text="Forgot your password?" linkText="Reset password" href="/forgot-password" />
          <FooterLink text="Don't have an account?" linkText="Sign up" href="/signup" />
        </>
      }
    />
  );
}

// * VALIDATOR
const authValidator = withZod(
  z.object({
    email: z.string().email({ message: "Please enter a valid email address" }),
    password: z.string().min(1, { message: "Please enter your password" }),
  })
);

// * ACTION
export const action = async ({ request }: ActionArgs): Promise<TypedJsonResponse<TypedActionResponse>> => {
  const response = new Response();
  const formData = await request.formData();
  const supabase = getSupabaseServerClient({ request, response });
  const result = await authValidator.validate(formData);
  if (result.error) return typedjson({ data: result.error, message: "Validation failed", type: "error" }, { status: 400 });
  else
    return typedjson(
      { data: result.data, message: "Success!", type: "success", status: 200 },
      {
        headers: response.headers,
      }
    );
};
